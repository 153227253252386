import React, { Component } from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';

const Container = styled.div`
	display: grid;
	grid-gap: 10px;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
`;

const Stack = styled.div`
	border-radius: 5px;
	padding: 20px;
	font-size: 150%;
	vertical-align: baseline;
`;

class OurStack extends Component {
	render() {
		let { data, links: { siteMetadata: { stackLinks } } } = this.props;
		return (
			<div className="pt-3">
				<Container>
					{Object.keys(data).map((key, index) => (
						<Stack key={key}>
							<a target="_blank" rel="noopener noreferrer" href={stackLinks[key]}>
								<Img fluid={data[key].childImageSharp.fluid} />
							</a>
						</Stack>
					))}
				</Container>
			</div>
		);
	}
}


export { OurStack };
