import React from 'react';
import Layout from '../components/layout';
import { OurStack } from '../components/Stack';
import { graphql, Link } from 'gatsby';

const Showcase = ({ data }) => {
	let links = data.links;
	let dataImages = Object.assign({}, data);
	delete dataImages.links;
	return (
		<Layout>
			<div className="justify-center items-center container text-black mx-auto p-6 text-left pb-40 pt-24 md:pt-18 sm:pt-18">
				<div className="pb-5">
					<svg height="7" width="40">
						<line x1="0" y1="0" x2="100" y2="0" style={{ stroke: '#1CF5BA', strokeWidth: '6' }} />
					</svg>{' '}
					<span className="font-medium tracking-wide text-black ml-2">THE TOOLS WE USE</span>
				</div>
				<h1 className="xl:text-5xl lg:text-5xl md:text-5xl text-4xl pb-5 text-black">
					We are experts on this fields
				</h1>
				<h2 className="text-2xl font-thin text-black-us-light leading-normal">
					We feel privileged to be part of a very experimented and smart team. Come and join with us.
				</h2>
				{links ? <OurStack data={dataImages} links={links} /> : null}
				{/* CLIENTES */}
				<div className="mt-8">
					<Link
						to="/contact"
						className="uppercase tracking-wide bg-green-us-light p-5 text-black hover:text-green-us-light no-underline hover-button2 font-bold"
					>
						Work with us
					</Link>
				</div>
			</div>
		</Layout>
	);
};

export const query = graphql`
	{
		aws: file(relativePath: { eq: "stack/aws.png" }) {
			childImageSharp {
				fluid(maxWidth: 600) {
					...GatsbyImageSharpFluid_tracedSVG
				}
			}
		}
		graphql: file(relativePath: { eq: "stack/graphql.png" }) {
			childImageSharp {
				fluid(maxWidth: 600) {
					...GatsbyImageSharpFluid_tracedSVG
				}
			}
		}
		gatsby: file(relativePath: { eq: "stack/gatsby.png" }) {
			childImageSharp {
				fluid(maxWidth: 600) {
					...GatsbyImageSharpFluid_tracedSVG
				}
			}
		}
		react: file(relativePath: { eq: "stack/react.png" }) {
			childImageSharp {
				fluid(maxWidth: 600) {
					...GatsbyImageSharpFluid_tracedSVG
				}
			}
		}
		go: file(relativePath: { eq: "stack/go.png" }) {
			childImageSharp {
				fluid(maxWidth: 600) {
					...GatsbyImageSharpFluid_tracedSVG
				}
			}
		}
		node: file(relativePath: { eq: "stack/node.png" }) {
			childImageSharp {
				fluid(maxWidth: 600) {
					...GatsbyImageSharpFluid_tracedSVG
				}
			}
		}
		circleci: file(relativePath: { eq: "stack/circleci.png" }) {
			childImageSharp {
				fluid(maxWidth: 600) {
					...GatsbyImageSharpFluid_tracedSVG
				}
			}
		}
		jest: file(relativePath: { eq: "stack/jest.png" }) {
			childImageSharp {
				fluid(maxWidth: 600) {
					...GatsbyImageSharpFluid_tracedSVG
				}
			}
		}
		#vue: file(relativePath: { eq: "stack/vue.png" }) {
		#	childImageSharp {
		#		fluid(maxWidth: 600) {
		#			...GatsbyImageSharpFluid_tracedSVG
		#		}
		#	}
		#}
		serverless: file(relativePath: { eq: "stack/serverless.png" }) {
			childImageSharp {
				fluid(maxWidth: 600) {
					...GatsbyImageSharpFluid_tracedSVG
				}
			}
		}
		netlify: file(relativePath: { eq: "stack/netlify.png" }) {
			childImageSharp {
				fluid(maxWidth: 600) {
					...GatsbyImageSharpFluid_tracedSVG
				}
			}
		}
		docker: file(relativePath: { eq: "stack/docker.png" }) {
			childImageSharp {
				fluid(maxWidth: 600) {
					...GatsbyImageSharpFluid_tracedSVG
				}
			}
		}
		links: site {
			siteMetadata {
				stackLinks {
					aws
					graphql
					gatsby
					react
					serverless
					go
					netlify
					node
					circleci
					vue
					jest
					docker
				}
			}
		}
	}
`;

export default Showcase;
